import React from 'react'
import Layout from "../components/Layout";

import ContactImage from "../images/contact-img.png"
import '../styles/contact.css'

function ContactPage() {
  return (
    <Layout>
      <div className="contact-container">
        <div className="contact-image" style={{ backgroundImage: `url(${ContactImage})` }}></div>

        <div className="contact-content">

          <div className="content-title" style={{ marginBottom: 'inherit' }}>email</div>
          <div className="content-description" style={{ marginBottom: 'inherit' }}>for questions and project inquries</div>
          <div className="content-description" style={{ textDecoration: 'underline', marginBottom: 'inherit' }}>me@erict.dev</div>

          <hr className="full-divider" style={{ margin: '20px 0' }}/>

          <div className="content-title" style={{ marginBottom: 'inherit' }}>office</div>
          <div className="content-description" style={{ marginBottom: 'inherit' }}>
            200 Spectrum Center Dr Suite 300
            <br/>
            Irvine, CA 92618
            <br/>
            949-436-9169
            <br/>
            Monday - Friday | 9:00am - 5:00pm
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default ContactPage;
